import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getLngFromUrl } from "../../i18n"; // importa la función getLngFromUrl del archivo i18n.js

export const LanguageSwitcher = ({ languageSelected, setLanguageSelected }) => {
  const { i18n } = useTranslation();
  const { pathname, search, hash } = useLocation();

  // obtener el idioma actual de la URL usando getLngFromUrl
  const currentLanguage =
    getLngFromUrl(window.location.pathname) || i18n.language;

  const languages = [
    { code: "es", label: "Español", avatar: "https://img.invupos.com/HT%20Solutions/Language%20Switcher/Spanish.svg" },
    { code: "en", label: "English", avatar: "https://img.invupos.com/HT%20Solutions/Language%20Switcher/English.svg" },
  ];

  const switcher = (value) => {
    const lng = value;
    i18n.changeLanguage(lng);
    window.location.replace(`/${lng}${pathname}${search}${hash}`);
  };

  return (
    <Listbox value={currentLanguage} onChange={switcher}>
      <div className="relative">
        <Listbox.Button className="inline-flex relative w-full cursor-pointer border border-[#e7e7e7] cursor-default rounded-lg  bg-[#f1f2f3] py-4 pl-5 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <img
            className="h-4 mt-[1.5px] mr-3"
            src={languages.find(lang => lang.code === currentLanguage)?.avatar || ''}
          />

          <span className="block  text-[14px] truncate text-[#F0F0F0] ">
            {currentLanguage === "es" ? (
              <span className="block text-[14px] truncate text-[#18216D] hover:text-[#1f2e90]">
                Español
              </span>
            ) : currentLanguage === "en" ? (
              <span className="block text-sm font-medium text-[14px] truncate text-[#18216D] hover:text-[#1f2e90] ">
                English
              </span>
            ) : null}
          </span>

          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="3"
              stroke="currentColor"
              className="h-3 w-3  text-[#18216D] hover:text-[#a3a3a3] mr-2.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-2 w-26 w-full overflow-auto rounded-md bg-[#f1f2f3] py-1 text-[14px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {languages.map(({ code, label, avatar }) => (
              <Listbox.Option
                key={code}
                className={({ active }) =>
                  `relative cursor-default select-none py-4 pl-5 pr-2   ${
                    active
                      ? "bg-[#d9dcde] text-[#F0F0F0] text-[14px]"
                      : "text-[#F0F0F0] text-[14px]"
                  }`
                }
                value={code}
              >
                {({ selected }) => (
                  <>
                    <div className="flex">
                    <img className="h-5 pr-4" src={avatar} />
                      <span
                        className={`block truncate text-left ${
                          selected
                            ? "text-[14px] font-semibold  text-[#18216D] hover:text-[#1f2e90]"
                            : "text-[14px] font-normal  text-[#18216D] hover:text-[#1f2e90]"
                        }`}
                      >
                        {label}
                      </span>
                    </div>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
