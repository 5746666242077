import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationES from "./locales/es/translation.json";

const defaultLanguage = "es"; // Idioma predeterminado
const localStorageKey = "language";

// Comprobar si se ha establecido un idioma en el localStorage
const savedLanguage = localStorage.getItem(localStorageKey);

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: "localStorage",
  lookup(options) {
    const savedLanguage = localStorage.getItem(localStorageKey);
    return savedLanguage || defaultLanguage;
  },
});

export const languages = Object.entries(resources).map(([lang]) => lang);

export const removeLngPrefix = (pathname) => {
  for (let lang of languages) {
    if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
      return pathname.replace(`/${lang}`, "");
    }
  }
  return pathname;
};

export const getLngFromUrl = (pathname) => {
  for (let lang of languages) {
    if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
      return lang;
    }
  }
  return null;
};

const lng = getLngFromUrl(window.location.pathname) || i18n.language;

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: savedLanguage || defaultLanguage,
    resources,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      enabled: true,
    },
    ns: ["translation"],
    defaultNS: "translation",
    lng, // Establecer el idioma actual al valor almacenado o al idioma predeterminado
  });

export default i18n;
