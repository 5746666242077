import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const TechTalentRecruitment = () => {
  const { t } = useTranslation();
  const [animationComplete, setAnimationComplete] = useState(false);
  const [ref, inView] = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView && !animationComplete) {
      controls.start("visible");
      setAnimationComplete(true);
    } else if (!inView && animationComplete) {
      controls.start("hidden");
      setAnimationComplete(false);
    }
  }, [controls, inView, animationComplete]);

  return (
    <motion.div 
    ref={ref}
        animate={controls}
        initial="hidden"
        transition={{ duration: 0.5 }} // Aumenta la duración de la transición
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0.2, scale: 0.9 }, // Reduce la escala y la opacidad inicial
        }}
        id="Techlaw"
    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 mt-12">
    <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
      <div className="lg:order-last flex flex-col text-left justify-center">
        <div className="max-w-2xl mb-6">
          <h2 className="max-w-xl mb-6 font-motivabl text-3xl font-bold tracking-tight text-[#18216D] sm:text-[39px] sm:leading-tight">
            {t("Personalización")}
          </h2>
          <p className="text-base font-motivalg text-[#18216D] md:text-lg">
            {t("1. Materiales- contamos con diversidad de materiales y colores; linos, terciopelo, corduroy, suede, algodón, y más.")}
          </p>
          <p className="text-base font-motivalg text-[#18216D] md:text-lg">
            {t("2. Labels- tenemos la opción de personalizar tu Kippot con tu nombre, fecha del evento y lugar con un label, por impresión directa, o como parte de tu tela personalizada.")}
          </p>
          <p className="text-base font-motivalg text-[#18216D] md:text-lg">
            {t("3. Bordado- tus Kippot o Challah Cover pueden bordarse con tu logo o iniciales u otro diseño, además de tener una variedad de colores de hilos.")}
          </p>
          <p className="text-base font-motivalg text-[#18216D] md:text-lg">
            {t("4. Shapes- manejamos variedad de tamaños y estilos de Kippot, nos adaptamos totalmente a tus gustos, necesidades y costumbres.")}
          </p>
          
        </div>
      </div>
      <div>
        <img
          className="object-cover lg:mt-none -mt-5 w-full h-full sm:h-full"
          src="https://cdn.horovitz.dev/ylkippot/f2.jpg"
          alt=""
        />
      </div>
    </div>
  </motion.div>
  )
}

export default TechTalentRecruitment;