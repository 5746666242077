import React from 'react'
import { LanguageSwitcher } from '../../LanguageSwitcher';
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer class="bg-[#f7f8f8]">
  <div class="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
        <div class="flex justify-start text-teal-600 sm:justify-start">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">YL Kippot</span>
            <img className="h-24 w-auto" src="https://cdn.horovitz.dev/ylkippot/ylkippot-logo.png" alt="YL Kippot" />
          </a>
        </div>

        <p
          class="mt-6 max-w-md text-left font-motivalg text-[#18216D] leading-relaxed sm:max-w-xs sm:text-left"
        >
          {t("")}
        </p>
      </div>

      <div
        class="grid grid-cols-2 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:col-span-2"
      >
        <div class="text-left sm:text-left">
          <p class="text-xl font-motivabl text-[#18216D]">{t("Contact Us")}</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <a
                class="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                href="/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 shrink-0 text-[#18216D]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>

                <span class="flex-1 text-base font-motivalg text-[#18216D]">hello@ylkippot.com </span>
              </a>
            </li>

            <li>
              <a
                class="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                href="/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 shrink-0 text-[#18216D]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>

                <span class="flex-1 text-base font-motivalg text-[#18216D]">+50760218463</span>
              </a>
            </li>
            <div>
              {/* <div class="flex justify-start text-teal-600 sm:justify-start">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">YL Kippot</span>
                  <img className="h-24 w-auto" src="https://cdn.horovitz.dev/ylkippot/ylkippot-logo.png" alt="YL Kippot" />
                </a>
              </div>

              <p
                class="mt-6 max-w-md text-left font-motivalg text-[#18216D] leading-relaxed sm:max-w-xs sm:text-left"
              >
                {t("")}
              </p> */}

              <div class="mt-6">
                <a href="https://www.instagram.com/ylkippot/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                  <FaInstagram class="h-5 w-5 shrink-0 text-[#18216D]" />
                  <span class="flex-1 text-base font-motivalg text-[#18216D]">ylkippot</span>
                </a>
              </div>
            </div>
            {/* <li
              class="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 shrink-0 text-[#18216D]"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <address class="-mt-0.5 flex-1 not-italic text-base font-motivalg text-[#18216D]">
              {t("50th Street and 44th Street, San Francisco, Banistmo Building, YL Kippot offices")}
              </address>
            </li> */}
          </ul>
        </div>

        {/* <div class="text-left sm:text-left">
          <p class="text-xl font-motivabl text-[#18216D]">{t("Our Services")}</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <Link 
              to="Techlaw"
              activeClass="active"
              spy={true}
              smooth={true}
                className="text-base font-motivalg text-[#18216D] cursor-pointer"
              >
                {t("Personalización")}
              </Link>
            </li>

            <li>
            <Link 
              to="AWSManagement"
              activeClass="active"
              spy={true}
              smooth={true}
              className="text-base font-motivalg text-[#18216D] cursor-pointer"
              >
                {t("Eventos")}  
              </Link>
            </li>

            <li>
            <Link 
              to="Eventos"
              activeClass="active"
              spy={true}
              smooth={true}
              className="text-base font-motivalg text-[#18216D] cursor-pointer"
              >
                {t("Eventos")}
              </Link>
            </li>

          </ul>
        </div> */}

        <div class="text-left sm:text-left">
          <p class="text-xl font-motivabl text-[#18216D]">{t("Languages")}</p>

          <ul class="mt-8 space-y-4 text-sm">
            <LanguageSwitcher/>
          </ul>
        </div>

      </div>
    </div>

    <div class="mt-12 border-t border-gray-100 pt-6">
      <div class="text-center sm:flex sm:justify-between sm:text-left">
        {/* <p class="text-sm text-gray-500">
          <a
            class="inline-block text-sm font-motivabl text-[#18216D] underline transition duration-500 ease-in-out hover:text-[#FE6545]"
            href="https://img.invupos.com/HT%20Solutions/Privacy%20Policy/Privacy%20Policy%20HT%20Solutions.pdf"
            target="_blank"
          >
            {t("Privacy Policy")}
          </a>
        </p> */}

        <p class="mt-4 text-sm font-motivalg text-[#18216D] sm:order-first sm:mt-0">
          &copy; {t("2024 All rights reserved  YL Kippot")}
        </p>
      </div>
    </div>
  </div>
</footer>
  )
}

export default Footer;